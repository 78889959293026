import { FileType, getFileType } from './FileType.utils';

import { AssetGallery } from './AssetGallery';
import { AudioPlayer } from './AudioPlayer';
import { FileCard } from './FileCard';
import React from 'react';
import { SecurityAlert } from './SecurityAlert';

export interface FileContentProps {
  fileUrls: string[];
}

export const FileContent = ({ fileUrls }: FileContentProps) => {
  const [notSupportedFileTypes, imagesAndVideos, audioFiles] = fileUrls.reduce(
    (acc, url) => {
      const type = getFileType(url);

      if (type === FileType.IMAGE || type === FileType.VIDEO) {
        acc[1].push(url);
      } else if (type === FileType.AUDIO) {
        acc[2].push(url);
      } else {
        acc[0].push(url);
      }
      return acc;
    },
    [[] as string[], [] as string[], [] as string[]],
  );

  return (
    <>
      <AssetGallery fileUrls={imagesAndVideos} />

      {audioFiles.map(value => (
        <AudioPlayer key={value} src={value} />
      ))}

      {notSupportedFileTypes.length > 0 && <SecurityAlert />}

      {notSupportedFileTypes.map(value => (
        <FileCard key={value} value={value} />
      ))}
    </>
  );
};
