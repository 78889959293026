'use client';

import { useState } from 'react';
import { useTranslations } from 'next-intl';

import { Link } from '@zealy/design-system';

import { cn } from '#utils/utils';

export interface TextContentProps {
  children: string;
  expandLabel?: string;
  className?: string;
}

const MaxParagraphLength = 134;

export const TextContent = ({ children, expandLabel, className }: TextContentProps) => {
  const t = useTranslations('common');
  const [expanded, setExpanded] = useState(false);
  const isExpandable = children.length > MaxParagraphLength;

  return (
    <span className={cn('body-component-md inline-block', className)}>
      <p className="inline text-primary">
        {isExpandable && !expanded ? `${children.substring(0, MaxParagraphLength)}...` : children}
      </p>

      {isExpandable && !expanded && (
        <Link
          as="button"
          onClick={() => setExpanded(true)}
          className="hover:underline underline-offset-2 pl-100"
        >
          {expandLabel ?? t('more')}
        </Link>
      )}
    </span>
  );
};
