'use client';

import React, { useEffect, useState } from 'react';

import { Button, cardInfo, sizeFormatter } from '@zealy/design-system';
import { FileDownloadLine, FileLine } from '@zealy/icons';

export const FileCard = ({ value }: { value: string }) => {
  const [file, setFile] = useState<File>();
  let fileName = '';

  try {
    fileName = new URL(value).pathname.split('/').pop()?.slice(-28) ?? '';
  } catch (e) {
    // We don't need to do anything
  }

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await fetch(value);
        const data = await response.blob();
        const metadata = { type: data.type };
        setFile(new File([data], fileName ?? 'file', metadata));
      } catch (e) {
        console.log(e);
      }
    };
    fetchFile();
  }, [value, fileName]);

  return (
    <a
      href={value}
      download={fileName}
      className="p-file-upload-file-card rounded-file-upload-file-card-container border border-component-tertiary flex flex-row gap-file-upload-file-card-info-x items-center flex-1 transition-colors hover:bg-component-quaternary-hover active:bg-component-quaternary-press outline-none"
    >
      <div className={cardInfo.iconContainer}>
        <FileLine className={cardInfo.icon} />
      </div>
      <div className={cardInfo.infoWrapper}>
        <span className={cardInfo.fileName}>{file?.name ?? fileName}</span>
        <span className={cardInfo.sizeLabel}>{sizeFormatter.format(file?.size ?? 0)}</span>
      </div>
      <Button onlyIcon variant="ghost" leftIcon={<FileDownloadLine />} />
    </a>
  );
};
