'use client';

import React, { useState } from 'react';
import clsx from 'clsx';

import { Button } from '@zealy/design-system';
import { PlusLine } from '@zealy/icons';

import { cn } from '#utils/utils';

import { SubmissionCardDialog } from './AssetInspect';
import { FileType, getFileType } from './FileType.utils';

export interface SubmissionCardImageProps {
  src: string;
  alt?: string;
  className?: string;
  dynamicHeight?: boolean;
  onClick: () => void;
}

const SubmissionCardImage = ({
  src,
  alt,
  className,
  dynamicHeight,
  onClick,
}: SubmissionCardImageProps) => {
  const fileType = getFileType(src);

  if (fileType === FileType.NOT_SUPPORTED) return null;

  return (
    <button className={clsx('overflow-hidden w-full min-h-0 h-full block')} onClick={onClick}>
      {fileType === FileType.IMAGE ? (
        <img
          src={src}
          alt={alt ?? ''}
          className={cn(
            'object-cover w-full max-h-[500px]',
            dynamicHeight ? 'h-full' : 'h-[214px]',
            className,
          )}
        />
      ) : (
        <video
          src={src}
          controls={!dynamicHeight}
          className={clsx('object-cover w-full', dynamicHeight ? 'h-full' : 'h-[214px]', className)}
        />
      )}
    </button>
  );
};

export interface SubmissionCardImgGalleryProps {
  images: Array<string>;
  onImageClick: (index: number) => void;
}

const ImgGallery = ({ images, onImageClick }: SubmissionCardImgGalleryProps) => {
  if (images.length === 0) return null;

  if (images.length <= 2) {
    return (
      <div className={clsx('grid gap-25', images.length > 1 && 'grid-cols-2')}>
        {images.map((img, index) => (
          <SubmissionCardImage
            key={img}
            src={img}
            onClick={() => onImageClick(index)}
            dynamicHeight={images.length === 1}
            className={images.length === 1 ? 'object-contain' : undefined}
          />
        ))}
      </div>
    );
  }

  if (images.length === 3) {
    return (
      <div className="grid grid-cols-2 gap-25">
        <SubmissionCardImage src={images[0]} onClick={() => onImageClick(0)} />

        <div className="grid gap-25 h-[214px]">
          <SubmissionCardImage src={images[1]} dynamicHeight onClick={() => onImageClick(1)} />
          <SubmissionCardImage src={images[2]} dynamicHeight onClick={() => onImageClick(2)} />
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-25 h-[214px]">
      {images.slice(0, 4).map((image, index) => {
        const isLast = index === 3;

        return (
          <div className="overflow-hidden relative" key={image}>
            {images.length > 4 && isLast && (
              <Button
                leftIcon={<PlusLine />}
                variant="ghost"
                className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              >
                {images.length - 4}
              </Button>
            )}

            <SubmissionCardImage
              src={image}
              dynamicHeight
              onClick={() => onImageClick(index)}
              className={
                isLast && images.length > 4 ? 'opacity-30 hover:opacity-20 transition-opacity' : ''
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export const AssetGallery = ({ fileUrls }: { fileUrls: string[] }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>();

  return (
    <>
      <div className="rounded-component-lg overflow-hidden w-full">
        <ImgGallery images={fileUrls} onImageClick={setSelectedIndex} />
      </div>
      <SubmissionCardDialog
        open={selectedIndex !== undefined}
        onOpenChange={() => setSelectedIndex(undefined)}
        initialIndex={selectedIndex}
        images={fileUrls}
      />
    </>
  );
};
