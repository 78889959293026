import React from 'react';

import type { listTaskValidations } from '@zealy/queries';
import { Card } from '@zealy/design-system';
import { TwitterFilled } from '@zealy/icons';

import { AssetGallery } from '#components/Content/FileContent';
import { TextContent } from '#components/Content/TextContent';
import { getTweetURL } from '#utils/urls';

import { TweetActions } from './TweetActions';
import { TweetDateStamp } from './TweetDateStamp';
import { TweetMetrics } from './TweetMetrics';
import { TwitterUserInfo } from './TwitterUserInfo';

type TaskValidation = Awaited<ReturnType<typeof listTaskValidations>>['data'][number];

export type TweetMetadata = Extract<
  TaskValidation['metadata'],
  {
    input: { tweetId?: string };
  }
> & {
  actions?: ('like' | 'reply' | 'retweet')[];
};

export const Tweet = ({
  input: { tweetId },
  tweetMetrics,
  tweetCreator,
  createdAt,
  tweetText,
  tweetMedia,
  actions,
}: TweetMetadata) => {
  if (!tweetId) return null;

  const tweetUrl = getTweetURL(tweetId, tweetCreator?.username);

  return (
    <Card className="flex flex-col gap-100 relative p-200">
      {tweetCreator && <TwitterUserInfo {...tweetCreator} />}
      {tweetText && <TextContent>{tweetText}</TextContent>}
      {tweetMedia && <AssetGallery fileUrls={tweetMedia.map(({ url }) => url)} />}
      {createdAt && <TweetDateStamp date={new Date(createdAt)} />}
      {tweetMetrics && <TweetMetrics {...tweetMetrics} />}
      {!!actions?.length && <TweetActions actions={actions} tweetId={tweetId} />}
      <a target="_blank" rel="noopener noreferrer" href={tweetUrl}>
        <TwitterFilled className="absolute top-150 right-150 w-icon-md h-icon-md icon-[#1da1f2]" />
      </a>
    </Card>
  );
};
