export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  NOT_SUPPORTED = 'not-supported',
}

const EXTENSIONS = {
  image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'],
  video: ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv'],
  audio: ['mp3', 'wav', 'ogg', 'flac', 'm4a'],
};

export const ALLOWED_EXTENSIONS = [...EXTENSIONS.image, ...EXTENSIONS.video, ...EXTENSIONS.audio];

export function getFileType(uri: string): FileType {
  try {
    const url = new URL(uri);

    const extension = url.pathname.split('.').pop()?.toLowerCase();

    if (!extension) return FileType.NOT_SUPPORTED;

    if (EXTENSIONS.image.includes(extension)) return FileType.IMAGE;
    if (EXTENSIONS.video.includes(extension)) return FileType.VIDEO;
    if (EXTENSIONS.audio.includes(extension)) return FileType.AUDIO;

    return FileType.NOT_SUPPORTED;
  } catch (error) {
    return FileType.NOT_SUPPORTED;
  }
}
