import React from 'react';
import { useTranslations } from 'next-intl';

import { Avatar } from '@zealy/design-system';
import { VerifiedFilled } from '@zealy/icons';

import { getTwitterAccountURL } from '#utils/urls';
import { cn } from '#utils/utils';

import type { TweetMetadata } from './Tweet';

export const TwitterUserInfo = ({
  id,
  imageUrl,
  username,
  name,
  createdAt,
  followerCount,
  isVerified,
  size = 'md',
}: TweetMetadata['tweetCreator'] & {
  size?: 'md' | 'lg';
} = {}) => {
  const t = useTranslations('ds.social.twitter.user');
  const formatter = new Intl.NumberFormat(undefined, {
    notation: 'compact',
    compactDisplay: 'short',
  });

  const userUrl = username ? getTwitterAccountURL(username) : undefined;

  return (
    <div className={cn('flex gap-100 items-center', size === 'lg' && 'flex-col gap-200')}>
      <a href={userUrl} target="_blank" rel="noopener noreferrer">
        <Avatar size={size === 'md' ? 'sm' : 'lg'} name={name} src={imageUrl} />
      </a>
      <div
        className={cn('flex flex-col text-secondary body-component-sm', {
          'body-component-md gap-200': size === 'lg',
        })}
      >
        <div className={cn('flex gap-50 items-center', size === 'lg' && 'flex-col')}>
          <span
            className={cn(
              'text-primary',
              size === 'lg' ? 'body-component-lg-bold' : 'body-component-sm-bold',
            )}
          >
            {name}
            {isVerified && (
              <VerifiedFilled className="ml-50 w-icon-sm h-icon-sm text-twitter-interaction-reply-primary inline" />
            )}
          </span>

          {userUrl && (
            <a
              href={userUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline hover:text-primary"
            >
              @{username}
            </a>
          )}
        </div>
        {(!!followerCount || !!createdAt) && (
          <div className="flex gap-50 items-center">
            {!!followerCount && (
              <>
                <span>
                  {t('followers', {
                    count: followerCount,
                    formattedCount: formatter.format(followerCount),
                  })}
                </span>
                <span>·</span>
              </>
            )}
            {createdAt && (
              <span>
                {t('joined', {
                  date: new Date(createdAt),
                })}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
