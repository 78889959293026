import React, { useRef } from 'react';

const metaOrCtrl = (event: KeyboardEvent) => event.metaKey || event.ctrlKey;

const asciiToCode = (ascii: string) => {
  if (ascii.length > 1) return ascii;

  const char = ascii[0].toUpperCase();
  if (char >= 'A' && char <= 'Z') return `key${char.toLowerCase()}`;
  if (char >= 'a' && char <= 'z') return `key${char}`;
  if (char >= '0' && char <= '9') return `digit${char}`;
  return char;
};

export function isMacintosh() {
  return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Mac') > -1;
}

const specialKeyOrder: {
  [key: string]: number;
} = {
  cmd: 1,
  ctrl: 2,
  alt: 3,
  shift: 4,
};

const eventToKey = (event: KeyboardEvent) =>
  `${metaOrCtrl(event) ? 'cmd+' : ''}${event.altKey ? 'alt+' : ''}${
    event.shiftKey ? 'shift+' : ''
  }${event.code ? event.code.toLowerCase() : ''}`;

export const formatShortcutString = (shortcut: string) => {
  const keys = shortcut.toLowerCase().split('+');

  return keys
    .sort((keyA, keyB) => {
      const orderA = specialKeyOrder[keyA] || 6;
      const orderB = specialKeyOrder[keyB] || 6;
      return orderA - orderB;
    })
    .map(key => asciiToCode(key))
    .join('+');
};

export const useKeyboardShortcut = (
  shortcuts: { [key: string]: () => void },
  options?: { debug?: boolean; enableOnInteractive?: boolean },
) => {
  const lowercaseShortcuts: { [key: string]: () => void } = Object.keys(shortcuts).reduce(
    (acc, key) => ({ ...acc, [formatShortcutString(key)]: shortcuts[key] }),
    {},
  );

  const lowercaseShortcutsRef = useRef(lowercaseShortcuts);
  const optionsRef = useRef(options);

  lowercaseShortcutsRef.current = lowercaseShortcuts;
  optionsRef.current = options;

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const lowerCaseShortCuts = lowercaseShortcutsRef.current;
      const opts = optionsRef.current;
      const elementFocused: Element | null = document.activeElement;
      const tagName = elementFocused?.tagName.toLowerCase(); // Get the tag name of the focused element
      const roleName = elementFocused?.role?.toLowerCase();
      const isContentEditable = elementFocused?.getAttribute('contenteditable') === 'true';

      // Check if the current focus is on an interactive element
      if (
        !opts?.enableOnInteractive &&
        ((!!tagName && ['input', 'textarea', 'button', 'select'].includes(tagName)) ||
          (!!roleName && ['menuitem'].includes(roleName)) ||
          isContentEditable)
      ) {
        if (opts?.debug) {
          console.log('Keydown event ignored:', event);
        }
        // Return early if focused element is interactive or contenteditable
        return;
      }

      const callback = lowerCaseShortCuts[eventToKey(event)] ?? lowerCaseShortCuts[event.key];

      if (opts?.debug) {
        console.log({ event: eventToKey(event), lowerCaseShortCuts, callback });
      }
      if (callback) {
        event.preventDefault();
        callback();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};
