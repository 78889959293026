'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { cn } from '../../utils/cn';
import { Kbd } from '../Kbd';
const TooltipProvider = (props) => _jsx(TooltipPrimitive.Provider, { delayDuration: 200, ...props });
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipPortal = TooltipPrimitive.Portal;
const TooltipContent = React.forwardRef(({ className, sideOffset = 12, side = 'bottom', shortcut, children, ...props }, ref) => (_jsx(TooltipPrimitive.Content, { ref: ref, sideOffset: sideOffset, side: side, className: cn('z-50 overflow-hidden rounded-tooltip border bg-tooltip py-tooltip-container-y px-tooltip-container-x text-tooltip-primary tooltip shadow-tooltip', 'data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade will-change-[transform,opacity]', shortcut ? 'flex gap-tooltip-container-x' : '', className), ...props, children: shortcut ? (_jsxs(_Fragment, { children: [_jsx("p", { children: children }), _jsx("div", { className: "flex gap-tooltip-keyboard-shortcuts-x", children: shortcut.map(shortcutKey => (_jsx(Kbd, { children: shortcutKey }, shortcutKey))) })] })) : (children) })));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, TooltipPortal };
