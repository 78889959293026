import React from 'react';
import { useTranslations } from 'next-intl';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@zealy/design-system';
import {
  BarchartLine,
  BookmarkLine,
  CommentLine,
  HeartLine,
  QuotesLine,
  RepeatLine,
} from '@zealy/icons';

import type { TweetMetadata } from './Tweet';

const icons = {
  replies: CommentLine,
  retweets: RepeatLine,
  quotes: QuotesLine,
  likes: HeartLine,
  views: BarchartLine,
  bookmarks: BookmarkLine,
} as const;

const TweetMetric = ({ count, type }: { count: number; type: keyof typeof icons }) => {
  const Icon = icons[type];
  const t = useTranslations('ds.social.twitter.metrics');

  const formatter = new Intl.NumberFormat(undefined, {
    notation: 'compact',
    compactDisplay: 'short',
  });

  return (
    <Tooltip>
      <TooltipTrigger className="flex items-center gap-50 text-secondary body-component-sm">
        <Icon className="w-icon-sm h-icon-sm" />
        <span>{formatter.format(count)}</span>
      </TooltipTrigger>
      <TooltipContent>
        {formatter.format(count)} {t(type)}
      </TooltipContent>
    </Tooltip>
  );
};

export const TweetMetrics = ({
  replyCount = 0,
  retweetCount = 0,
  quoteCount = 0,
  likeCount = 0,
  viewCount = 0,
  bookmarkCount = 0,
}: TweetMetadata['tweetMetrics'] = {}) => {
  return (
    <TooltipProvider>
      <div className="flex justify-between">
        <TweetMetric type="replies" count={replyCount} />
        <TweetMetric type="retweets" count={retweetCount} />
        <TweetMetric type="quotes" count={quoteCount} />
        <TweetMetric type="likes" count={likeCount} />
        <TweetMetric type="views" count={viewCount} />
        <TweetMetric type="bookmarks" count={bookmarkCount} />
      </div>
    </TooltipProvider>
  );
};
