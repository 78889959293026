import { cva } from 'class-variance-authority';
const root = cva(['flex flex-col p-alert gap-alert-container rounded-alert border relative'], {
    variants: {
        variant: {
            default: 'text-secondary',
            info: 'bg-alert-info border-alert-info text-alert-info-description',
            success: 'bg-alert-success border-alert-success text-alert-success-description',
            warning: 'bg-alert-warning border-alert-warning text-alert-warning-description',
            error: 'bg-alert-error border-alert-error text-alert-error-description',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
const text = cva(['flex w-full gap-alert-container alert-description'], {
    variants: {
        orientation: {
            horizontal: 'md:items-center',
            vertical: '',
        },
    },
    defaultVariants: {
        orientation: 'vertical',
    },
});
const title = cva(['alert-title leading-5'], {
    variants: {
        variant: {
            default: 'text-primary',
            info: 'text-alert-info-title',
            success: 'text-alert-success-title',
            warning: 'text-alert-warning-title',
            error: 'text-alert-error-title',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
const icon = cva(['w-alert-icon h-alert-icon'], {
    variants: {
        variant: {
            default: 'icon-primary',
            info: 'icon-alert-info',
            success: 'icon-alert-success',
            warning: 'icon-alert-warning',
            error: 'icon-alert-error',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
export const styles = {
    root,
    text,
    title,
    icon,
};
