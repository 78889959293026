import React from 'react';
import { useTranslations } from 'next-intl';

import { Button } from '@zealy/design-system';
import { CommentLine, HeartLine, RepeatLine } from '@zealy/icons';

export const TweetActions = ({
  actions,
  tweetId,
}: {
  actions: ('like' | 'reply' | 'retweet')[];
  tweetId: string;
}) => {
  const t = useTranslations('ds.social.twitter.reactions.button');

  const twitterActions = {
    like: () => window.open(`https://twitter.com/intent/like?tweet_id=${tweetId}`, '_blank'),
    retweet: () => window.open(`https://twitter.com/intent/retweet?tweet_id=${tweetId}`, '_blank'),
    reply: () => window.open(`https://twitter.com/intent/tweet?in_reply_to=${tweetId}`, '_blank'),
  };

  return (
    <div className="flex gap-100">
      {actions.includes('reply') && (
        <Button leftIcon={<CommentLine />} variant="ghost" onClick={twitterActions.reply}>
          {t('reply')}
        </Button>
      )}
      {actions.includes('retweet') && (
        <Button leftIcon={<RepeatLine />} variant="ghost" onClick={twitterActions.retweet}>
          {t('retweet')}
        </Button>
      )}
      {actions.includes('like') && (
        <Button leftIcon={<HeartLine />} variant="ghost" onClick={twitterActions.like}>
          {t('like')}
        </Button>
      )}
    </div>
  );
};
