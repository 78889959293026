import { cva } from 'class-variance-authority';
export const avatarStyles = cva([
    'relative overflow-hidden select-none inline-flex justify-center align-middle items-center cursor-pointer',
    'transition-all duration-150 ease-in-out',
    'ring-avatar ring-offset-[0px] outline-none ring-offset-[var(--ring-offset-color)]',
    'focus-visible:ring-offset-2 focus-visible:ring-2 focus-visible:ring-avatar-focus',
    'hover:ring-offset-2 hover:ring-2 hover:ring-avatar-hover',
    'active:ring-offset-2 active:ring-2 active:ring-avatar-press',
    'rounded-full flex-shrink-0 text-onbrand-primary',
], {
    variants: {
        size: {
            xxs: 'avatar-xxs h-avatar-xxs w-avatar-xxs',
            xs: 'avatar-xs h-avatar-xs w-avatar-xs',
            sm: 'avatar-sm h-avatar-sm w-avatar-sm',
            md: 'avatar-md h-avatar-md w-avatar-md',
            lg: 'avatar-md h-component-lg w-component-lg',
            xl: 'avatar-md h-component-xl w-component-xl',
        },
    },
});
export const avatarGroupStyles = cva([
    '[&_[data-scope=avatargroup][data-part=avatar]]:-ml-50',
    // TODO: This shadow is hardcoded for now because it's missing tokens. Will replace when value is provided
    '[&_[data-scope=avatargroup][data-part=avatar]]:shadow-[-4px_0px_4px_0px_rgba(51,51,51,0.45)]',
]);
