import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, forwardRef } from 'react';
import { AlertCircleLine, AlertTriangleLine, CheckCircleLine, CrossLine, InfoLine, } from '@zealy/icons';
import { cn } from '../../../src/utils/cn';
import { Button } from '../Button';
import { styles } from './Alert.styles';
const ICONS = {
    warning: AlertTriangleLine,
    default: InfoLine,
    info: InfoLine,
    error: AlertCircleLine,
    success: CheckCircleLine,
};
export const Alert = forwardRef(({ className, variant, title, description, actions, onClose, orientation = 'vertical', icon, children, ...props }, ref) => {
    return (_jsxs("div", { className: cn(styles.root({ variant }), className), ...props, ref: ref, children: [_jsxs("div", { className: cn(styles.text({ orientation })), children: [icon
                        ? cloneElement(icon, {
                            className: cn(styles.icon({ variant }), icon.props.className ?? ''),
                        })
                        : ICONS[variant ?? 'info']({ className: styles.icon({ variant }) }), _jsxs("div", { className: cn('flex flex-col gap-50 flex-1 min-w-0', {
                            'md:flex-row md:items-center': orientation === 'horizontal',
                        }), children: [title && _jsx("div", { className: styles.title({ variant }), children: title }), description && _jsx("div", { className: "min-w-0", children: description }), actions && (_jsx("div", { className: cn('flex gap-100 mt-50 justify-end', {
                                    'w-full': orientation === 'vertical',
                                    'ml-auto': orientation === 'horizontal',
                                    'md:pr-250': orientation === 'horizontal' && onClose,
                                }), children: actions }))] }), onClose && (_jsx(Button, { onlyIcon: true, leftIcon: _jsx(CrossLine, {}), variant: "ghost", mutedText: true, onClick: onClose, className: "absolute top-50 right-50", size: "sm", "aria-label": "Close alert" }))] }), children] }));
});
