import React from 'react';
import { useTranslations } from 'next-intl';

export const TweetDateStamp = ({ date }: { date: Date }) => {
  const t = useTranslations('common');
  const formatterTime = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
  });
  const formatterMonth = new Intl.DateTimeFormat(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <div className="text-secondary body-component-sm flex gap-50 pb-50">
      <span>{formatterTime.format(date)}</span>
      <span>·</span>
      <span>{formatterMonth.format(date)}</span>
    </div>
  );
};
