'use client';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fallback, Image, Root } from '@radix-ui/react-avatar';
import { Children, cloneElement, forwardRef, useMemo } from 'react';
import { cn } from '../../utils/cn';
import { imageSizes, useAvatarGroup } from './Avatar.context';
import { avatarGroupStyles, avatarStyles } from './Avatar.styles';
const AVATAR_DISPLAY_NAME = 'Avatar';
const getInitials = (name) => {
    if (!name)
        return 'Z';
    const [first, last = ''] = name.split(' ');
    return `${first[0]}${last[0] || ''}`;
};
export const Avatar = forwardRef(({ size: sizeProp = 'md', name = '', src, className, as: As, fallbackDelay = 400, ringOffsetColor = 'var(--color-bg-secondary)', ...props }, ref) => {
    const group = useAvatarGroup();
    const size = sizeProp ?? group.size;
    const imageSize = group.imageSize ?? imageSizes[size];
    const initials = useMemo(() => (name ? getInitials(name) : ''), [name]);
    const style = useMemo(() => ({ '--ring-offset-color': ringOffsetColor }), [ringOffsetColor]);
    const fallbackStyle = 'leading-1 flex h-full w-full items-center justify-center text-primary bg-brand-primary hover:bg-brand-primary-hover active:bg-brand-primary-press transition-colors ease-out duration-100';
    return (_jsx(Root, { tabIndex: 0, className: cn(avatarStyles({ size }), className), "data-scope": "avatar", "data-part": "root", ...props, style: style, ref: ref, children: !!src && !!name ? (_jsxs(_Fragment, { children: [_jsx(Image, { src: src, alt: name, width: imageSize, height: imageSize, className: "object-cover h-full w-full", "data-scope": "avatar", "data-part": "image", asChild: !!As, children: As ? _jsx(As, {}) : null }), _jsx(Fallback, { className: fallbackStyle, delayMs: fallbackDelay, children: initials })] })) : (_jsx("span", { className: fallbackStyle, children: initials })) }));
});
// Will use this reference in AvatarGroup.
Avatar.displayName = AVATAR_DISPLAY_NAME;
export const AvatarGroup = forwardRef(({ size, className, children, ...props }, ref) => {
    const avatars = Children.toArray(children)
        .filter(node => {
        const child = node;
        // @ts-expect-error displayName is manually applied so we can filter only avatar nodes
        return child.type.displayName === AVATAR_DISPLAY_NAME;
    })
        .map(node => {
        const child = node;
        return cloneElement(child, {
            className: cn(avatarStyles({ size }), child.props.className),
            'data-scope': 'avatargroup',
            'data-part': 'avatar',
            size,
        });
    });
    return (_jsx("div", { ref: ref, "data-scope": "avatargroup", "data-part": "group", className: cn(avatarGroupStyles(), className), ...props, children: avatars }));
});
