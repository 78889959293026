'use client';
import { createContext } from '../../utils/component.context';
export const imageSizes = {
    xxs: 20,
    xs: 24,
    sm: 32,
    md: 40,
    lg: 48,
    xl: 56,
};
export const [AvatarGroupProvider, useAvatarGroup] = createContext({
    name: 'AvatarGroupContext',
    providerName: 'AvatarGroupProvider',
    hookName: 'useAvatarGroup',
    strict: false,
    defaultValue: {
        size: 'md',
        imageSize: imageSizes.md,
    },
});
